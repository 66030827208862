<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="onPageChange" :queryParam="queryParam">
			<div slot="topButtonGroup" class="h-handle-button">
				<div class="h-b">
					<el-button type="primary" size="small" @click="issue">下发</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="exportExcel">导出</el-button>
				</div>
			</div>
			<p slot="elList">
				<el-table ref="stockinfoListTable" :data="dataSource.Result" border
					@selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
					<el-table-column v-for="(col, index, count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName"
						:render-header="bindFilter(queryParam, col, ignoreField)"
						:fixed="index == 0 && (!config || !config.isDetailDisplay)"
						:min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0"
						v-if="col.Visible && (!config || !config.isDetailDisplay || (config.isDetailDisplay && index < 2))">
						<template slot-scope="scope">
							<span v-bind:style="{ color: scope.row.ExceptionCount > 0 ? 'red' : '#1874CD' }"
								v-if="col.FieldName === 'OrderNo'" type="text" @click="rowClick(scope)"
								style="text-decoration:underline;cursor:pointer">{{ scope.row[col.FieldName] }}</span>
							<span v-else> {{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
					<!-- <el-table-column label="操作" :min-width="130" v-if="!config.isDetailDisplay">
                        <template slot-scope="scope">
                            <el-button @click="seeProductImg(scope.row,scope.$index)" v-right-code="'Customer_Right_Stock_SummaryStockInfo_SearchSummaryStockInfo'" size="small" type="text">查看图片</el-button>
                            <div v-bind:class="'img'+ scope.$index" v-viewer v-show="false">
                                <img v-for="src in scope.row.ImgList" :src="src" :key="src">
                            </div>
                        </template>
                    </el-table-column> -->
				</el-table>
			</p>
		</fixed-list>
	</div>
</template>
<script>
export default {
	mounted() {
		this.Utils.lazy(() => {
			this.Event.$on("reloadPageList", () => this.reloadPageList());
		});
		var _this = this;
		this.initialize();
	},
	data() {
		return {
			ignoreField: [],
			multipleSelection: [],
			queryParam: {
				PageIndex: 1,
				PageSize: 10,
				Params: {},
				ViewStatus: 1,
				TabStatus: 0,
				TabShow: true,
				TabItems: [],
			},
			dataSource: {
				ColDefs: {},
				Result: [],
				TotalCount: 0
			},
			stockDealOptions: {
				visible: false,
				size: 'large'
			},
			stockDealConfig: {
				isDetailDisplay: Object.freeze(true)
			},
			queryParamDetail: {
				PageIndex: 1,
				PageSize: 10,
				Params: {}
			},
			ProductCode: '',
			isShowZeroStock: true,
			isShowStopStock: true,
		}
	},
	props: {
		config: {
			isDetailDisplay: false
		},
		option: {}
	},
	methods: {
		reloadPageList: function () {
			this.initialize();
		},
		initialize() {
			this.onPageChange(this.queryParam);
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
			this.$emit("onCheckRow", this.multipleSelection);
		},
		onPageChange(param) {
			var _this = this;
			this.queryParam = param;
			this.$ajax.query("omsapi/dispatch/getinlist", "post", param, data => {
				_this.dataSource = data;
				_this.queryParam.TabItems = data.TabItems;
			});
		},
		rowClick: function (scope) {
			this.onChangeEditDataSource(scope.row.Id);
		},
		onDataSourceChange(ds) {
			var _this = this;

			_this.dataSource = {
				ColDefs: {
					BodyFieldParams: []
				},
				Result: [],
				TotalCount: 0
			};
			_this.$nextTick(function () {
				_this.dataSource = ds;

			});
		},
		onChangeEditDataSource(data) {
			this.config.isDetailDisplay = true;
			this.$emit("onChangeEditDataSource", data);
		},
		exportExcel: function () {
			// var _this = this;
			// var str = encodeURIComponent(JSON.stringify(_this.queryParam));
			// var urlFile = "omsapi/stock/stockinfo/exportstock?queryParam=" + str;
			// _this.Utils.export(urlFile);
		},
		issue: function () {
			if (this.multipleSelection == null || this.multipleSelection.length == 0) {
				this.Utils.messageBox(`未选订单.`, "error");
				return false;
			}
			if(this.multipleSelection.filter(m=>m.IssueStatus == 100).length>0){
				this.Utils.messageBox(`请选择未下发的订单.`, "error");
				return false;
			}
			var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
			var _this = this;
			this.$ajax.send("omsapi/dispatch/issueOrder", "post", ids, (data) => {
				if (data.IsSuccess){
					_this.Utils.messageBox(data.OperationDesc, "warn");
					_this.onPageChange(_this.queryParam);
				}
			});
		}
	},
	components: {
	}
}
</script>
